import { useState, FormEvent, useEffect } from "react";
import Dialog from "SKNUI/yodel/yodel";
import { goFetch } from "SKNUI/fetch/fetch";
import { User } from "SKNUI/interfaces/user";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-light-svg-icons/faCircle";
import { faCrown } from "@fortawesome/pro-solid-svg-icons/faCrown";
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faBullseyeArrow } from "@fortawesome/pro-solid-svg-icons/faBullseyeArrow";

import Button from "Static/components/button";
import TextField from "SKNUI/text-field/text-field";
import Arcane from "SKNUI/arcane/arcane";
import GoogleLoginBtn from "SKNUI/google/button";
import loadGoogleAuthAPIs from "SKNUI/google/gapi-loader";

import { useEmailInput } from "SKNUI/scripts/use-input";

interface Props {
  loading: boolean;
  user: User;
}

export default function SignUpDialog(props: Props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail, emailError] = useEmailInput("");
  const [emailConfirm, setEmailConfirm] = useState<string | undefined>(
    undefined
  );

  const [rating, setRating] = useState("4.5");
  const [ratingCount, setRatingCount] = useState<number | undefined>(100);

  function handleSubmit(e: FormEvent) {
    if (emailError && email === emailConfirm) {
      setLoading(true);
    } else {
      e.preventDefault();
    }
  }

  const showCallback = () => {
    loadGoogleAuthAPIs();

    goFetch("GET", "/api/v1/experiencesatisfaction/", {
      days: 30,
    })
      .then((response: { avg: number; count: number }) => {
        let rating, ratingCount;
        rating = response.avg;
        if (rating) {
          rating = rating.toFixed(1);
          ratingCount = response.count;
          setRating(rating);
        }
        setRatingCount(ratingCount);
      })
      .catch((e) => {
        throw new Error(e);
      });
  };

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  return (
    <Dialog
      id="dialog-sign-up"
      baseModifierClass="SignupDialog"
      trackingName="Sign Up"
      title="Start your free career test"
      onDialogShow={showCallback}
      loading={loading}
    >
      <div data-testid="dialog-signup">
        <div className="Dialog-content">
          <p>We’ll email you a link to let you log back into your account.</p>
        </div>

        <form
          className="Dialog-form"
          method="post"
          onSubmit={handleSubmit}
          action={props.user.environment.memberRegistrationUrl}
        >
          <input type="hidden" name="registration_form" value="email_only" />
          <input
            type="hidden"
            name="csrfmiddlewaretoken"
            value={props.user.csrfToken}
          />

          <Arcane
            label="Email"
            field={
              <TextField
                fieldClass="Dialog-input"
                type="email"
                name="email"
                id="email"
                placeholder="you@example.com"
                required={true}
                onChange={({ currentTarget }) => setEmail(currentTarget.value)}
              />
            }
          />
          <Arcane
            label="Confirm Email"
            error="Email must match"
            field={
              <TextField
                okay={
                  (email && emailConfirm && email === emailConfirm) ||
                  !emailConfirm
                }
                fieldClass="Dialog-input"
                type="email"
                name="email_confirm"
                id="email_confirm"
                required={true}
                onChange={({ currentTarget }) =>
                  setEmailConfirm(currentTarget.value)
                }
              />
            }
          />

          <Button extra="Dialog-button bold" fill color="black" text="Start" />
        </form>

        <GoogleLoginBtn
          divStyle="Dialog-google-div"
          buttonStyle="Dialog-google-button"
          displayError={true}
        />

        <footer className="Dialog-footer">
          <div className="Dialog-footer-item">
            <div className="fa-layers fa-fw Dialog-footer-icon">
              <FontAwesomeIcon icon={faCircle} />
              <FontAwesomeIcon icon={faCrown} transform="shrink-7 left-2" />
            </div>
            <div className="Dialog-footer-text">
              <strong>Find your perfect career</strong>
              <br />
              <small>
                CareerExplorer has helped millions of users discover new
                careers.
              </small>
            </div>
          </div>
          <div className="Dialog-footer-item">
            <div className="fa-layers fa-fw Dialog-footer-icon">
              <FontAwesomeIcon icon={faCircle} />
              <FontAwesomeIcon icon={faStar} transform="shrink-6 left-1" />
            </div>
            <div className="Dialog-footer-text">
              <strong>Top in our class</strong>
              <br />
              <small>
                {ratingCount} people have rated their experience {rating}
                /5 in the last 30 days.
              </small>
            </div>
          </div>
          <div className="Dialog-footer-item">
            <div className="fa-layers fa-fw Dialog-footer-icon">
              <FontAwesomeIcon icon={faCircle} />
              <FontAwesomeIcon icon={faBullseyeArrow} transform="shrink-6" />
            </div>
            <div className="Dialog-footer-text">
              <strong>The most accurate matches</strong>
              <br />
              <small>
                Results from the world’s most advanced career test for free.
              </small>
            </div>
          </div>
          <a className="Dialog-footer-link" href="/career-test/">
            Learn more about CareerExplorer
          </a>
        </footer>
      </div>
    </Dialog>
  );
}
