import { FC, ReactNode } from "react";
import { NavMenuItem } from "./global-nav";

const GlobalNavItem: FC<NavMenuItem> = (props: NavMenuItem) => {
  const { variant = "default" } = props;
  return (
    <>
      <a
        className={`GlobalNav-menu-link GlobalNav-menu-link--${variant}`}
        data-track="mixpanel"
        data-target={props.name}
        key={props.name}
        data-link-type="Header"
        data-qa-id={props["data-qa-id"]}
        onClick={(e) => {
          if (props.handler) {
            e.preventDefault();
            props.handler();
          }
        }}
        href={props.link ? props.link : "#"}
        {...(props.ariaLabel && { "aria-label": props.ariaLabel })}
      >
        {props.anchor ? props.anchor : props.name}
        {props.flag && <span className="new-pill">{props.flag}</span>}
      </a>
      {props.submenu && (
        <ul className="GlobalNav-sub-menu">
          {props.submenu.map(
            (item: NavMenuItem): ReactNode => {
              const { variant = "default" } = item;
              return (
                <li
                  key={item.name}
                  className={`GlobalNav-menu-item GlobalNav-menu-item--${variant}`}
                >
                  <GlobalNavItem {...item} />
                </li>
              );
            }
          )}
        </ul>
      )}
    </>
  );
};

export default GlobalNavItem;
