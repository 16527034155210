// This is the abstract component that lives in sknui
import {
  Component,
  cloneElement,
  ReactElement,
  MouseEvent,
  ReactNode,
} from "react";
import focusWithin from "focus-within";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-solid-svg-icons";

import GlobalNavItem from "./global-nav-item";

export type NavMenu = NavMenuItem[];

export interface NavMenuItem {
  submenu?: NavMenu;
  name: string;
  anchor?: ReactNode;
  link?: string;
  handler?: (event?: MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  ariaLabel?: string;
  mobileOnly?: boolean;
  "data-qa-id"?: string;
  flag?: string;
  variant?: string;
}

interface Props {
  mainMenu: NavMenu;
  mainMenuExtra?: ReactNode[];
  asideMenu?: ReactNode[];
  logo: ReactNode;
  classNames?: string;
}

interface State {
  menuExpanded: boolean;
  activeSubmenu: NavMenu | null;
}

export default class GlobalNav extends Component<Props, State> {
  private constructor(props: Props) {
    super(props);
    this.state = {
      menuExpanded: false,
      activeSubmenu: null,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  public componentDidMount(): void {
    window.addEventListener("resize", this.handleResize);
    focusWithin(document);
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", this.handleResize);
  }

  private handleResize(): void {
    this.setState({
      menuExpanded: window.innerWidth >= 1010 ? false : this.state.menuExpanded,
      activeSubmenu:
        window.innerWidth >= 1010 ? null : this.state.activeSubmenu,
    });
  }

  private toggleMenu(e: MouseEvent): void {
    e.preventDefault();

    this.setState({
      menuExpanded: !this.state.menuExpanded,
      activeSubmenu: null,
    });
  }

  public toggleSubmenu(e: MouseEvent, submenu: NavMenu): void {
    e.preventDefault();

    if (window.innerWidth < 1010) {
      this.setState({
        activeSubmenu: this.state.activeSubmenu === submenu ? null : submenu,
      });
    }
  }

  public render() {
    return (
      <>
        <nav
          className={`GlobalNav${
            this.state.menuExpanded ? " GlobalNav--open" : ""
          }${this.props.classNames ?? ""}`}
          role="navigation"
        >
          <a
            className="GlobalNav-logo--desktop"
            data-track="mixpanel"
            data-target="Home"
            data-link-type="Header"
            href="/"
          >
            {this.props.logo}
          </a>

          <a
            className="GlobalNav-menu-button"
            href="#"
            data-track="mixpanel"
            data-target="Toggle Menu"
            data-link-type="Header"
            onClick={(e): void => this.toggleMenu(e)}
            aria-label={this.state.menuExpanded ? "Close Menu" : "Open Menu"}
          >
            <FontAwesomeIcon
              className="GlobalNav-menu-hamburger"
              icon={faBars}
            />
            {this.props.logo
              ? cloneElement(this.props.logo as ReactElement, {
                  id: "mobile",
                })
              : null}
          </a>

          <ul className="GlobalNav-menu">
            {this.props.mainMenu.map(
              (item: NavMenuItem): ReactNode => (
                <li
                  className={`GlobalNav-menu-item${
                    item.mobileOnly ? " GlobalNav-menu-item--mobile" : ""
                  }`}
                  key={item.name}
                >
                  <GlobalNavItem
                    name={item.name}
                    link={item.link}
                    submenu={item.submenu}
                    key={item.name}
                    variant={item.variant}
                    flag={item.flag}
                  />
                </li>
              )
            )}
            {this.props.mainMenuExtra &&
              this.props.mainMenuExtra.map(
                (node: ReactNode, i: number): ReactNode => (
                  <li className="GlobalNav-menu-item" key={`extra-${i}`}>
                    {node}
                  </li>
                )
              )}
          </ul>

          {this.props.asideMenu && this.props.asideMenu.map((item) => item)}
        </nav>
        <div className="progress-container" id="progress-bar-container">
          <div className="progress-bar" id="progress-bar" />
        </div>
      </>
    );
  }
}
