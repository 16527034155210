interface Props {
  step: number;
}

const StepCounter = ({ step }: Props) => {
  return (
    <span className="Dialog-subtitle" data-testid="step-counter">
      Step {step} of 2
    </span>
  );
};

export default StepCounter;
