import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";

import StepCounter from "./step-counter";
import { Elements } from "@stripe/react-stripe-js";
import { trackMixpanelEvent } from "SKNUI/mixpanel/mixpanel";
import goFetch from "SKNUI/fetch/fetch";
import { debounce } from "debounce";

import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutFormOrgs from "SKNUI/payment/checkout-form-self-serve";
import { User } from "SKNUI/interfaces/user";

interface Props {
  coupon?: string; // assume coupon is valid, undefined or empty if not
  subscriptionRate?: number;
  stripePublishableAPIKey: string;
  signupData?: string;
  user: User;
}

interface ResponseWithPaymentIntentSecret {
  reason?: string;
  reason_technical?: string;
  secret?: string;
  price?: number;
}

export default function OrgDialogConfirmPayment(props: Props) {
  const [stripePromise, setStripePromise] = useState<
    undefined | Stripe | PromiseLike<Stripe | null> | null
  >();
  const [secret, setSecret] = useState<string>();
  const [price, setPrice] = useState<number>();

  const fetchPaymentIntentSecretAndPrice = async () => {
    if (props.subscriptionRate) {
      let path = "/organizations/purchase-v3/";
      const data = {
        price: props.subscriptionRate,
        orgName: props.signupData,
      };
      let res = await goFetch<
        { price: number | undefined; orgName: string | undefined },
        ResponseWithPaymentIntentSecret
      >("GET", path, data);
      if (!res.secret) {
        throw new Error(res.reason_technical);
      }
      setSecret(res.secret);
      setPrice(res.price);
    }
  };

  const debouncedfetchPaymentIntentSecretAndPrice = debounce(
    fetchPaymentIntentSecretAndPrice,
    500
  );

  // is used in onPaymentSuccess which is used in checkout form
  const notifyPaymentSucceeded = async () => {
    if (!secret) {
      throw new ReferenceError("Client secret is empty");
    }
    const data = { secret: secret };
    const path = "/organizations/purchase-v2/succeeded/";

    try {
      await goFetch<{ secret: string }, { status: string }>("POST", path, data);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  // is used in checkout form
  const onPaymentSuccess = async () => {
    trackMixpanelEvent("Premium Purchased (front-end)", { isBulk: true });
    await notifyPaymentSucceeded();
    //create the actual org here post successful payment
    const path = "/organizations/deferred-register/";
    const data = { organization__name: props.signupData };
    try {
      await goFetch<
        { organization__name: string | undefined },
        { status: string }
      >("POST", path, data);
    } catch (e) {
      Sentry.captureException(e);
    }

    window.location.href = "/organizations/";
  };

  //#region Effects

  useEffect(() => {
    debouncedfetchPaymentIntentSecretAndPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!stripePromise && props.stripePublishableAPIKey) {
      const stripe = loadStripe(props.stripePublishableAPIKey);
      setStripePromise(stripe);
    }
  }, [props.stripePublishableAPIKey, stripePromise]);

  //#endregion

  if (stripePromise && props.subscriptionRate) {
    return (
      <div
        id="premium-dialog-confirm-payment"
        data-testid="premium-confirm-payment"
      >
        <StepCounter step={2} />
        <h1 className="Dialog-title">{"Payment"}</h1>

        <Elements stripe={stripePromise}>
          <CheckoutFormOrgs
            coupon={props.coupon}
            paymentIntentSecret={secret}
            subscriptionRate={price}
            onSuccess={onPaymentSuccess}
            buttonText={"Complete"}
            buttonExtra={"confirm-payment-button alans-butt--black"}
            user={props.user}
          />
        </Elements>
      </div>
    );
  }
  return <div data-testid="premium-confirm-payment"></div>;
}
