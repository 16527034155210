import { Component, ReactNode } from "react";

import TextField from "SKNUI/text-field/text-field";
import Button from "Static/components/button";
import StepCounter from "./step-counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";
import Arcane from "SKNUI/arcane/arcane";
import { User } from "SKNUI/interfaces/user";
import { openModal } from "SKNUI/yodel/utils";

interface Props {
  handleSubmit: () => void;
  user: User;
  price: number;
  handleSignupSubmit?: (orgName: string | null) => void;
}
interface State {
  value: string;
  isLoading: boolean;
  email?: string;
  full_name?: string;
  password?: string;
  organization__name?: string;
  errors: {
    member__email?: string;
    member__full_name?: string;
    member__password?: string;
    organization__name?: string;
  };
}

export type SignupForm = Pick<
  State,
  "full_name" | "organization__name" | "email" | "password"
> | null;

export default class EmailSignup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public state: State = {
    value: "",
    isLoading: false,
    errors: {},
    email: this.props.user.email,
    full_name: "",
    organization__name: "",
    password: "",
  };

  public componentDidMount() {
    this.showFormErrors();
  }

  private showFormErrors() {
    let state = {};
    if (URLSearchParams) {
      const errorQueryParam = new URLSearchParams(window.location.search).get(
        "dialog-form-errors"
      );
      if (errorQueryParam) {
        const errors = JSON.parse(errorQueryParam);
        state = Object.assign(state, { errors });
      }
      const dataQueryParam = new URLSearchParams(window.location.search).get(
        "dialog-form-data"
      );
      if (dataQueryParam) {
        const data = JSON.parse(dataQueryParam);
        state = Object.assign(state, {
          full_name: data.member__full_name,
          organization__name: data.organization__name,
          email: data.member__email,
        });
      }
      this.setState(state);
    }
  }

  public emailField(): ReactNode {
    const { errors, email } = this.state;
    return (
      <div
        style={
          this.props.user.authenticated && !this.props.user.deferred
            ? { display: "none" }
            : {}
        }
      >
        <Arcane
          label="Email Address"
          error={errors.member__email}
          field={
            <TextField
              fieldClass="Dialog-input"
              type="email"
              name="member__email"
              id="member__email"
              placeholder="Enter your email address"
              aria-labelledby="aria-sign-up-email"
              required={true}
              okay={errors.member__email ? false : true}
              value={email}
              onChange={(e) => {
                this.setState({ email: e.currentTarget.value });
              }}
            />
          }
        />
      </div>
    );
  }

  public markupBulkVariant(): ReactNode {
    const { user } = this.props;
    const { errors, full_name, organization__name } = this.state;
    return (
      <>
        {!user.name && (
          <Arcane
            label="Full Name"
            error={errors.member__full_name}
            field={
              <TextField
                fieldClass="Dialog-input"
                type="text"
                name="member__full_name"
                id="member__full_name"
                placeholder="Your Full Name"
                aria-labelledby="aria-sign-up-name"
                required={true}
                okay={errors.member__full_name ? false : true}
                onChange={(e) => {
                  this.setState({ full_name: e.currentTarget.value });
                }}
                value={full_name}
              />
            }
          />
        )}
        {!user.isOrgAdmin ? (
          <Arcane
            label="Organization Name"
            error={errors.organization__name}
            field={
              <TextField
                fieldClass="Dialog-input"
                type="text"
                name="organization__name"
                id="organization__name"
                placeholder="Your Organization"
                aria-labelledby="aria-sign-up-org"
                required={true}
                okay={errors.organization__name ? false : true}
                onChange={(e) => {
                  this.setState({ organization__name: e.currentTarget.value });
                }}
                value={organization__name}
              />
            }
          />
        ) : null}
        {this.emailField()}
        {!user.authenticated ||
        (user.authenticated && !user.hasUseablePassword) ? (
          <Arcane
            label="Password"
            error={errors.member__password}
            field={
              <TextField
                fieldClass="Dialog-input"
                type="password"
                name="member__password"
                id="member__password"
                placeholder="Your Password"
                aria-labelledby="aria-sign-up-password"
                required={true}
                okay={errors.member__password ? false : true}
                onChange={(e) => {
                  this.setState({ password: e.currentTarget.value });
                }}
              />
            }
          />
        ) : null}
        <p className="Dialog-form-subtext">{/*😏*/}</p>
      </>
    );
  }

  public handleSubmit() {
    this.props.handleSignupSubmit?.(this.state.organization__name ?? null);
    this.setState({ isLoading: true });
  }

  public render() {
    const { isLoading } = this.state;

    const titleText = "Register your account";

    return (
      <div id="premium-dialog-email-signup" data-testid="premium-email-signup">
        <StepCounter step={1} />
        <h1 className="Dialog-title" tabIndex={0}>
          {titleText}
        </h1>
        <form
          className="Dialog-form"
          onSubmit={() => this.handleSubmit()}
          method="POST"
          action={
            `/organizations/deferred-register-admin/` +
            `?price=${this.props.price}&orgName=${this.state.organization__name}`
          }
        >
          <input type="hidden" name="registration_form" value="email_only" />
          <input
            type="hidden"
            name="csrfmiddlewaretoken"
            value={this.props.user.csrfToken}
          />

          {this.markupBulkVariant()}

          {!this.props.user.authenticated && (
            <p>
              Already registered?{" "}
              <a href="#" onClick={() => openModal("dialog-login")}>
                Click here to login!
              </a>
            </p>
          )}

          <Button
            icon={
              isLoading ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : ""
            }
            text={isLoading ? "" : "Next"}
            extra="next-button alans-butt--black"
            disabled={isLoading}
            dataQAID="bulk-signup-button"
          />
        </form>
      </div>
    );
  }
}
