import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-light-svg-icons";

export function getDisplayPrice(price?: number): string {
  if (price !== undefined) {
    if (price % 1 === 0) {
      return `${Math.round(price)}`;
    }
    return `${price.toFixed(2)}`;
  }
  return "";
}

export const LockIcon = (
  <p className="Dialog-secured">
    <small>
      <FontAwesomeIcon icon={faLock} aria-hidden="true" /> Secured Connection
    </small>
  </p>
);
