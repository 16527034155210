import { useContext, MouseEvent, useState } from "react";
import goFetch from "SKNUI/fetch/fetch";

import { GoogleContext } from "./auth-provider";
import { GoogleIcon } from "./icon";
import { GoogleLoginResponse, GoogleResponse } from "./interfaces";

interface Props {
  divStyle?: string;
  buttonStyle?: string;
  displayError?: boolean;
}

export default function GoogleLoginBtn({divStyle, buttonStyle, displayError}: Props) {
  const { signIn, signOut, loginUrl } = useContext(GoogleContext);
  const [googleError, setGoogleError] = useState<string | undefined>();

  function handleGoogleResponse({ profileObj, tokenId } : GoogleLoginResponse) {
    if (loginUrl) {
      goFetch<{ email: string; token_id: string }, string>("POST", loginUrl, {
        email: profileObj.email,
        token_id: tokenId,
      })
        .then((response: GoogleResponse) => {
          // Successful response, we redirect to the URL given
          window.location.href = response.next_url;
        })
        .catch((e: Error) => {
          signOut(() => {}, () => console.log("Failed to Logout Google"));
          if (displayError) {
            setGoogleError(
              "Something went wrong authenticating with Google or an account may have already been registered at this email address."
            );
          } else {
            // Bad response, we should re-direct to the URL given to display error message
            window.location.href = JSON.parse(e.message).next_url;
          }
        });
    }
  }

  function handleGoogleFailure(e: { error: string }) {
    if (displayError && e.error === "idpiframe_initialization_failed") {
      setGoogleError(
        "Your browser has cookies disabled. Please enable them to allow Google sign-in."
      );
    }
  }

  function login(e: MouseEvent) {
    e.preventDefault();
    signIn(handleGoogleResponse, handleGoogleFailure);
  }

  return <>
    <div className={divStyle}>
      <button style={{ cursor: "pointer", paddingRight: 10, fontWeight: 500, paddingLeft: 0, paddingTop: 10, paddingBottom: 10, background: "#fff" }}
       className={buttonStyle} onClick={login}>
        <GoogleIcon/> Sign in with Google
      </button>
    </div>
    {displayError && googleError && (
      <label className="Error Error--google" role="alert">
        {googleError}
      </label>
    )}
  </>;
}
