import Dialog from "SKNUI/yodel/yodel";
import Button from "Static/components/button";

export default function ForgotPasswordDialog() {
  return (
    <Dialog
      id="dialog-forgot-password"
      baseModifierClass="ForgotDialog"
      trackingName="Forgot Password"
      title="Have you checked your email?"
    >
      <div className="Dialog-content" data-testid="dialog-fpassword">
        <p>
          We emailed you a login link when you signed up, so you likely don’t
          have or need a password. Just click the link to log back in at any
          time.
        </p>
        <p>
          If you’re still having trouble logging in, try the link below or
          contact our <a href="mailto:contact@sokanu.com">support team</a>.
        </p>
      </div>

      <Button
        link="/forgot-password/"
        extra="Dialog-button Dialog-button--forgot"
        fill
        color="black"
        text="Reset Password"
      />
    </Dialog>
  );
}
