import { createContext, ReactNode, useEffect, useState } from "react";

import { useIsMounted } from "SKNUI/listings/use-is-mounted";
import { rendererIsClient } from "SKNUI/scripts";
import {
  Context,
  Gapi,
  GoogleAuthType,
  GoogleLoginResponse,
} from "./interfaces";

interface GoogleWindow extends Window {
  gapi: Gapi;
}

declare let window: GoogleWindow;

export const GoogleContext = createContext<Context>({
  signIn: () => console.log("signIn not loaded"),
  signOut: () => console.log("signOut not loaded"),
  loginUrl: null,
});

interface Props {
  children: ReactNode;
  clientId: string;
  loginUrl: string;
}

const STATIC_PARAMS = {
  cookie_policy: "single_host_origin",
  ux_mode: "popup",
  fetch_basic_profile: true,
  access_type: "offline",
};

/**
 * A React Context Provider based on the 
 * internal logic of `react-google-login`
 *
 * How to consume: 
 * ```
 * const { signIn, signOut, loginUrl } = useContext(GoogleContext)
 * ```
 * and ensure a *parent* at some level is 
 * wrapped with the necessary `GoogleProvider`
 * like so:
 * ```
 * <GoogleProvider clientId={clientId} loginUrl={loginUrl}>
    <YourComponent {...props}/>
  </GoogleProvider>
 * ```
 * @see https://developers.google.com/identity/sign-in/web/reference
 */
export function GoogleProvider({ children, clientId, loginUrl }: Props) {
  const isMounted = useIsMounted();
  const [googleAuth, setGoogleAuth] = useState<undefined | GoogleAuthType>();

  async function signIn(
    success?: (res: GoogleLoginResponse) => void,
    fail?: (e: { error: string }) => void
  ) {
    if (isMounted.current) {
      if (googleAuth) {
        googleAuth.signIn().then((r) => success?.(handleSigninSuccess(r)), fail);
      } else {
        fail?.({ error: "idpiframe_initialization_failed" });
      }
    }
  }

  async function signOut(success?: () => void, fail?: () => void) {
    if (isMounted.current) {
      if (googleAuth) {
        await googleAuth?.signOut().then(success, fail);
        googleAuth?.disconnect();
      } else {
        success?.();
      }
    }
  }

  async function dialGoogle() {
    try {
      const init = await window.gapi.auth2.init({
        client_id: clientId,
        ...STATIC_PARAMS,
      });
      if (init && isMounted.current) {
        setGoogleAuth(init);
      }
    } catch (error) {
      console.log("Failed to init Google");
    }
  }

  useEffect(() => {
    if (rendererIsClient()) {
      (async () => {
        while (!window.hasOwnProperty("gapi")) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        window.gapi?.load("auth2", async () => {
          const google = await window.gapi.auth2.getAuthInstance();
          if (isMounted.current) {
            if (google) {
              setGoogleAuth(google);
            } else {
              dialGoogle();
            }
          }
        });
      })();
    }
  }, []);

  return (
    <GoogleContext.Provider value={{ signOut, signIn, loginUrl }}>
      <>{children}</>
    </GoogleContext.Provider>
  );
}

function handleSigninSuccess(res: GoogleLoginResponse) {
  const basicProfile = res.getBasicProfile();
  const authResponse = res.getAuthResponse(true);
  res.googleId = basicProfile.getId();
  res.tokenObj = authResponse;
  res.tokenId = authResponse.id_token;
  res.accessToken = authResponse.access_token;
  res.profileObj = {
    googleId: basicProfile.getId(),
    imageUrl: basicProfile.getImageUrl(),
    email: basicProfile.getEmail(),
    name: basicProfile.getName(),
    givenName: basicProfile.getGivenName(),
    familyName: basicProfile.getFamilyName(),
  };
  return res;
}
