interface Props {
  name?: string;
  className?: string;
}

export function UserIcon({ name, className }: Props) {
  function initials(name: string | undefined) {
    if (!name || name === " ") {
      return "CE";
    }
    if (!name.includes(" ")) {
      return name[0];
    }
    const its = name
      .split(" ")
      .map((characters: string) => characters[0])
      .join("");
    return its.substring(0, 3);
  }

  return <span className={className}>{initials(name)}</span>;
}
