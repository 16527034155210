import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";

interface Props {
  toggleMobileAside: () => void;
  getDisplayPrice: (arg0?: number) => void;
  showMobileAside: boolean;
  price: number;
}

const CheckMark = <FontAwesomeIcon icon={faCheck} aria-hidden="true" />;

export const Aside = (props: Props) => {
  return (
    <aside
      data-testid="premium-aside"
      className={`Dialog-content__section-aside ${
        props.showMobileAside ? "" : "Dialog-content__section-aside--hidden"
      }`}
    >
      <div className="Dialog-content__section-aside__box">
        <div className="Dialog-content__section-aside__box-grid">
          <div className="Dialog-content__section-aside__logo">
            <img
              src="/static/compiled/images/logo-square-black-small.png"
              alt="Career Explorer Logo"
            />
          </div>
          <div className="Dialog-content__section-aside__blurb">
            <span className="Dialog-content__section-aside__box-title">
              {"CareerExplorer for Colleges & Universities"}
            </span>
            <p>Unlimited accounts and a dashboard to manage results.</p>
          </div>
          <span className="Dialog-content__section-aside__total-cost">
            TOTAL COST{" "}
          </span>
          <span className="Dialog-content__section-aside__price-discount"></span>
          <span className={`Dialog-content__section-aside__price`}>
            ${props.getDisplayPrice(props.price)}{" "}
            <span className="currency-code"> USD / year</span>
          </span>
          <span
            className="Dialog-content__section-aside__mobile-toggle"
            onClick={() => {
              props.toggleMobileAside();
            }}
            aria-hidden="true"
          >
            {props.showMobileAside ? (
              <>
                Hide Details <FontAwesomeIcon icon={faChevronUp} />
              </>
            ) : (
              <>
                Show Details <FontAwesomeIcon icon={faChevronDown} />
              </>
            )}
          </span>
        </div>
        <hr />

        <span>
          <strong>{"What's included:"}</strong>
        </span>
        <ul>
          <li>{CheckMark} Unlimited licenses</li>
          <li>{CheckMark} Admin dashboard</li>
          <li>{CheckMark} Email invites to users</li>
        </ul>
      </div>
    </aside>
  );
};
