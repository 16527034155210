import { MouseEvent, useState } from "react";
import Dialog from "SKNUI/yodel/yodel";

import Button from "Static/components/button";
import TextField from "SKNUI/text-field/text-field";
import GoogleLoginBtn from "SKNUI/google/button";
import loadGoogleAuthAPIs from "SKNUI/google/gapi-loader";
import Arcane from "SKNUI/arcane/arcane";
import { User } from "SKNUI/interfaces/user";

interface Props {
  user: User;
  handleForgotPasswordClick: (e: MouseEvent) => void;
}

export default function LoginDialog({
  user: { environment, csrfToken },
  handleForgotPasswordClick,
}: Props) {
  const [loading, setLoading] = useState(false);

  const showCallback = () => {
    loadGoogleAuthAPIs();
  };

  return (
    <Dialog
      id="dialog-login"
      baseModifierClass="LoginDialog"
      title="Login with email"
      trackingName="Login"
      onDialogShow={showCallback}
      loading={loading}
    >
      <div className="Dialog-content" data-testid="dialog-login">
        <p>
          Check your registration email for an automatic login link. Or, login
          below if you’ve set a password in the past.
        </p>
      </div>

      <form
        className="Dialog-form"
        method="post"
        onSubmit={() => setLoading(true)}
        action={`${environment.memberLoginUrl}${
          typeof window !== "undefined" && window.location.search
            ? `${encodeURIComponent(window.location.href)}`
            : ""
        }`}
      >
        <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />

        <Arcane
          label="Email"
          field={
            <TextField
              fieldClass="Dialog-input"
              type="email"
              name="email"
              id="login-email"
              required={true}
              placeholder="you@example.com"
              aria-label="Email Address"
              data-qa-id="login-email-input"
            />
          }
        />

        <Arcane
          label="Password"
          field={
            <TextField
              fieldClass="Dialog-input"
              type="password"
              name="password"
              id="login-password"
              required={true}
              placeholder="password"
              aria-label="Password"
              data-qa-id="login-password-input"
            />
          }
        />

        <a
          href="#"
          onClick={(e) => handleForgotPasswordClick(e)}
          className="Dialog-forgot-password-link"
        >
          Forgot Password
        </a>

        <Button
          dataQAID="login-submit-button"
          extra="Dialog-button"
          fill
          color="black"
          text="Login"
        />
      </form>

      <GoogleLoginBtn
        divStyle="Dialog-google-div"
        buttonStyle="Dialog-google-button"
        displayError={true}
      />
    </Dialog>
  );
}
