import { renderComponentToDOM } from "SKNUI/scripts/utils";
import CareerExplorerGlobalNav from "SKNUI/global-nav/local-global-nav";
import "Static/utils/lazysize";

const copy = [
  "Need to run? Save your progress and come back any time!",
  "Don't lose your progress! Add an email address to your account now.",
  "Claim your data! You can remove yourself from our database any time.",
];
const popOverCopy = copy[Math.round(Math.random() * (copy.length - 1))];

renderComponentToDOM(CareerExplorerGlobalNav, "globalnav-container", {
  popOverCopy,
});
