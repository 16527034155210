import { useEffect, useState } from "react";
import QueryString from "query-string";

import EmailSignup from "./email-signup-self-serve";
import { Aside } from "./aside-self-serve";
import { trackMixpanelEvent } from "SKNUI/mixpanel/mixpanel";
import Dialog from "SKNUI/yodel/yodel";
import type { User } from "SKNUI/interfaces/user";
import { getDisplayPrice, LockIcon } from "./utils";
import { openModal } from "SKNUI/yodel/utils";
import OrgDialogConfirmPayment from "./confirm-payment-self-serve";

interface Props {
  user: User;
}

function persistURLState(obj: Record<string, unknown>) {
  const search = window.location.search;
  const currParams = QueryString.parse(search);
  const newParams = {
    ...currParams,
    ...obj,
  };
  history.replaceState({}, "", `?${QueryString.stringify(newParams)}`);
}

export function PaymentModal({ user }: Props) {
  const { environment } = user;
  const [price, setPrice] = useState<number>();
  const [orgName, setOrgName] = useState<string>();
  const [showMobileAside, setShowMobileAside] = useState(false);
  const [open, setOpen] = useState<boolean>(false);

  ////////////////////////////
  ///    DIALOG METHODS    ///
  ////////////////////////////

  function attachDOMTargets(callback: (dialogPrice: number) => void) {
    const links = Array.prototype.slice.call(
      document.querySelectorAll(`[data-dialog-target="dialog-payment"]`),
      0
    );
    links.forEach((link: HTMLAnchorElement) => {
      link.addEventListener("click", (e: Event) => {
        const dataset = (e.target as HTMLAnchorElement).dataset;

        if (dataset.dialogPrice) {
          persistURLState({ price: dataset.dialogPrice });
          callback(+dataset.dialogPrice);
        }
      });
    });
  }

  function launchDialogOnMount() {
    attachDOMTargets((dialogPrice: number) => setPrice(dialogPrice));
    if (window.location.search.indexOf("dialog-self-serve-open=true") > -1) {
      openModal("dialog-payment");
    }
  }

  useEffect(() => {
    // reload data after redirect to payment after email signup
    const params = QueryString.parse(window.location.search);
    if (params?.price) {
      setPrice(+params.price);
    }
    if (params?.orgName) {
      setOrgName(`${params.orgName}`);
    }

    window.addEventListener("load", launchDialogOnMount);

    return () => {
      window.removeEventListener("load", launchDialogOnMount);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleEmailSignupSubmit(orgName: string) {
    persistURLState({ orgName: orgName });
  }

  // eslint-disable-next-line no-confusing-arrow
  const renderStep = () =>
    price && orgName ? paymentStepMarkup() : emailStepMarkup();

  const emailStepMarkup = () => (
    <>
      <section className="Dialog-content__section">
        <div className="Dialog-content__section-main">
          {price && (
            <EmailSignup
              handleSubmit={() => {
                /** silence */
              }}
              user={user}
              price={price}
              handleSignupSubmit={handleEmailSignupSubmit}
            />
          )}
        </div>

        {asideMarkup()}
      </section>
      {LockIcon}
    </>
  );

  function paymentStepMarkup() {
    if (environment.stripePublishableApiKey) {
      return (
        <>
          <section className="Dialog-content__section">
            <div className="Dialog-content__section-main">
              <OrgDialogConfirmPayment
                subscriptionRate={price}
                stripePublishableAPIKey={environment.stripePublishableApiKey}
                signupData={orgName}
                user={user}
              />
            </div>
            {asideMarkup()}
          </section>
          {LockIcon}
        </>
      );
    }
    throw new Error(`Tried to load checkout while Stripe API Key was not set.`);
  }

  const asideMarkup = () =>
    price ? (
      <Aside
        toggleMobileAside={() => setShowMobileAside(!showMobileAside)}
        getDisplayPrice={getDisplayPrice}
        showMobileAside={showMobileAside}
        price={price}
      />
    ) : null;

  ////////////////////////////
  ///         API          ///
  ////////////////////////////

  return (
    <Dialog
      id="dialog-payment"
      trackingName="Self Serve"
      dialogModifierClass="Dialog--Bulk"
      onDialogHide={() => {
        trackMixpanelEvent("Self Serve Modal Closed", { Price: price });
        setShowMobileAside(false);
      }}
      onDialogShow={() => {
        setOpen(true);
      }}
    >
      <div className="Dialog-content" data-testid="dialog-payment">
        {open ? renderStep() : <></>}
      </div>
    </Dialog>
  );
}
