import { ReactElement, useState, FormEvent, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons/faSpinnerThird";
import {
  useStripe,
  useElements,
  CardElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
} from "@stripe/stripe-js";

import { goFetch } from "SKNUI/fetch/fetch";
import TextField from "SKNUI/text-field/text-field";
import Arcane from "SKNUI/arcane/arcane";
import Button from "Static/components/button";
import { User } from "SKNUI/interfaces/user";

interface Props {
  subscriptionRate?: number;
  paymentIntentSecret?: string;
  coupon?: string;
  onSuccess?: () => void;
  cardElementOptions?: StripeCardElementOptions;
  buttonColor?: string;
  buttonText?: string;
  buttonSize?: string;
  buttonExtra?: string;
  user: User;
}

interface NewSubscriptionResponse {
  status: "success" | "error" | "retry" | "action_required";
  reason?: string;
  data?: {
    subscription_id: string;
    subscription_status: string;
    payment_intent_status?: string;
    payment_intent_secret?: string;
  };
}

const DEFAULT_CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  style: {
    base: {
      fontFamily:
        'aktiv-grotesk, aktiv-grotesk-std, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#6f6f6f",
      },
    },
  },
};

export default function CheckoutFormOrgs(props: Props): ReactElement {
  const [error, setError] = useState<string>();
  const [fullName, setFullName] = useState<string>();
  const [needsName, setNeedsName] = useState<boolean>(false);
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [retryId, setRetryId] = useState<string>();
  const stripe = useStripe();
  const elements = useElements();

  const handleNameChange = (value: string) => {
    setFullName(value);
    setNeedsName(!value);
  };

  //#region Subscription handlers

  const retrySubscription = (paymentMethodId: string) => {
    if (!retryId) {
      throw new ReferenceError("RetryId is invalid");
    }
    const path = `/api/v1/payment/retry-subscription/${retryId}/`;
    const body = { payment_method: paymentMethodId };
    return goFetch<{ payment_method: string }, NewSubscriptionResponse>(
      "POST",
      path,
      body
    );
  };

  const handleSubscriptionShared = (paymentMethodId: string) => {
    const path = "/api/v1/payment/new-subscription/";
    const body = {
      payment_method: paymentMethodId,
      coupon: props.coupon,
      subscription_rate: props.subscriptionRate,
    };
    return goFetch<
      { payment_method: string; coupon: string | undefined },
      NewSubscriptionResponse
    >("POST", path, body);
  };

  const handleSubscription = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      throw new ReferenceError("Cannot find card element.");
    }
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: fullName,
        email: props.user.email,
      },
    });

    if (result.error || result.paymentMethod === undefined) {
      setError(
        result.error?.message ??
          "Unexpected error occurred. Please try again later."
      );
      return;
    }

    let subResult: NewSubscriptionResponse;
    if (retryId) {
      subResult = await retrySubscription(result.paymentMethod.id);
    } else {
      subResult = await handleSubscriptionShared(result.paymentMethod.id);
    }

    switch (subResult.status) {
      case "error":
        setError(
          subResult.reason ??
            "Unexpected error occurred. Please try again later."
        );
        break;

      case "retry":
        setRetryId(subResult.data?.subscription_id ?? undefined);
        setError(
          "Failed to charge card. Please double check your card and try again."
        );
        break;

      case "action_required":
        // Consider showing a dialog explaining user needs extra verification on their end
        if (!subResult.data) {
          throw new ReferenceError("No subscription data received.");
        }
        if (!subResult.data.payment_intent_secret) {
          throw new ReferenceError(
            "No payment intent secret return. Server error?"
          );
        }
        stripe.confirmCardPayment(subResult.data.payment_intent_secret);
        break;

      case "success":
        setSucceeded(true);
        if (props.onSuccess) {
          props.onSuccess();
        }
        break;

      default:
        throw new Error("Unexpected response from server");
    }
  };

  const handleSubscriptionPaymentRequest = async (
    ev: PaymentRequestPaymentMethodEvent
  ) => {
    if (!stripe) {
      // Stripe.js has not loaded yet.
      return;
    }
    let result: NewSubscriptionResponse;
    if (retryId) {
      result = await retrySubscription(ev.paymentMethod.id);
    } else {
      result = await handleSubscriptionShared(ev.paymentMethod.id);
    }

    switch (result.status) {
      case "error":
        ev.complete("fail");
        setError(
          result.reason ?? "Unexpected error occurred. Please try again later."
        );
        break;

      case "retry":
        ev.complete("fail");
        setRetryId(result.data?.subscription_id ?? undefined);
        setError(
          "Failed to charge card. Please double check your card and try again."
        );
        break;

      case "action_required":
        ev.complete("fail");
        // Consider showing a dialog explaining user needs extra verification on their end
        if (!result.data) {
          throw new ReferenceError("No subscription data received.");
        }
        if (!result.data.payment_intent_secret) {
          throw new ReferenceError(
            "No payment intent secret return. Server error?"
          );
        }
        stripe.confirmCardPayment(result.data.payment_intent_secret);
        break;

      case "success":
        ev.complete("success");
        setSucceeded(true);
        if (props.onSuccess) {
          props.onSuccess();
        }
        break;

      default:
        throw new Error("Unexpected response from server");
    }
  };

  //#endregion

  //#region Event handlers

  // Handle real-time validation errors from the card Element.
  const handleChange = (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError("");
    }
  };

  // Handle form submission.
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (window.ga) {
      window.ga("send", {
        hitType: "event",
        eventCategory: "Membership Modal", // rename?
        eventAction: "clicked",
        eventLabel: "Buy Now",
      });
    }

    if (!fullName) {
      setError("Please enter your name as it appears on your card");
      return;
    }

    if (loading || succeeded) {
      return;
    }
    setLoading(true);

    try {
      await handleSubscription();
    } catch (e) {
      setLoading(false);
      throw e;
    }

    setLoading(false);
  };

  //#endregion

  //#region Effects

  useEffect(() => {
    if (stripe && props.subscriptionRate !== undefined) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Sokanu Organization Dashboard",
          amount: props.subscriptionRate,
        },
        requestPayerName: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on("paymentmethod", async (ev) => {
        await handleSubscriptionPaymentRequest(ev);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, props.subscriptionRate]);

  //#endregion

  const getButtonText = () => {
    if (loading || succeeded) {
      return loading ? "" : "Done";
    }
    return props.buttonText ?? "Buy now";
  };

  return (
    <form className="CheckoutForm" onSubmit={handleSubmit}>
      {paymentRequest && !props.coupon && (
        <div>
          <PaymentRequestButtonElement
            options={{
              paymentRequest: paymentRequest,
              style: {
                paymentRequestButton: {
                  theme: "dark",
                  height: "64px",
                },
              },
            }}
            className="PaymentRequestButton"
          />
          <div className="CheckoutForm-paymentrequest-copy">
            <span>Or</span>
          </div>
        </div>
      )}

      <Arcane
        label="Full Name On Card"
        field={
          <TextField
            fieldClass="Dialog-input"
            type="text"
            name="cardholder"
            id="cardholder"
            placeholder="Jane Smith"
            value={fullName}
            aria-labelledby="aria-cardholder"
            required={true}
            onChange={(e) =>
              handleNameChange((e.currentTarget as HTMLInputElement).value)
            }
            okay={!needsName}
          />
        }
      />

      <div className="form-row">
        <label htmlFor="card-element" className="Label">
          Credit or debit card
        </label>
        <CardElement
          id="card-element"
          className="TextField Checkout-stripe-field"
          options={props.cardElementOptions ?? DEFAULT_CARD_ELEMENT_OPTIONS}
          onChange={handleChange}
        />
      </div>

      {error ? <div className="CheckoutForm-error">{error}</div> : ""}

      <p>
        Payments are processed externally by{" "}
        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
          Stripe
        </a>{" "}
        - we do not store your card information. Please consult our{" "}
        <a href="/terms/" tabIndex={-1}>
          Terms of Service
        </a>{" "}
        and our <a href="/privacy/">Privacy Policy</a>.
      </p>

      <Button
        icon={loading ? <FontAwesomeIcon icon={faSpinnerThird} spin /> : ""}
        text={getButtonText()}
        color={props.buttonColor}
        size={props.buttonSize}
        extra={props.buttonExtra}
        disabled={loading || succeeded}
      />
    </form>
  );
}
