import { Component, ReactNode } from "react";
import { NullState } from "SKNUI/interfaces/class";

interface Props {
  id?: string;
  "data-testid"?: string;
  text: string | ReactNode;
  size?: string; // TODO: Update to only accept existing sizes
  color?: string; // TODO?: Update to only accept existing colours
  fill?: boolean;
  extra?: string; // TODO?: Update to only accept existing other classes?
  icon?: ReactNode;
  onClick?: () => void;
  link?: string;
  dataTrack?: string;
  dataTarget?: string;
  dataLinkType?: string;
  dataDialogTarget?: string;
  dataDialogFirstSlide?: string;
  newTab?: boolean;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isSubmit?: boolean;
  outline?: boolean;
  hasChildren?: boolean;
  children?: ReactNode;
  onPageNav?: boolean;
  onPageNavOffset?: number;
  dataQAID?: string;
}

export default class Button extends Component<Props, NullState> {
  public componentDidMount(): void {
    // test stub
  }

  public handleLinkClick(): void {
    // Handle dialogs
    if (this.props.dataDialogTarget) {
      window.dispatchEvent(
        new CustomEvent("dialog:open", {
          detail: {
            target: this.props.dataDialogTarget,
          },
        })
      );
    }
  }

  public render() {
    let style: string = !this.props.size
      ? "Button"
      : "Button" + "-" + this.props.size;
    style = !this.props.color ? style : style + " " + this.props.color;
    style = !this.props.fill ? style : style + " fill";
    style = this.props.text ? style : style + " no-text";
    style = !this.props.extra ? style : style + " " + this.props.extra;
    style = !this.props.outline ? style : style + " outline";

    let icon: ReactNode = this.props.icon ? (
      <span className="Button-icon">{this.props.icon}</span>
    ) : (
      ""
    );

    if (this.props.link) {
      const target = this.props.newTab
        ? {
            target: "_blank",
            rel: "noreferrer",
          }
        : undefined;

      return (
        <a
          id={this.props.id}
          data-testid={this.props.id}
          data-dialog-target={this.props.dataDialogTarget}
          data-dialog-first-slide={this.props.dataDialogFirstSlide}
          onClick={(): void => this.handleLinkClick()}
          data-track={this.props.dataTrack}
          data-target={this.props.dataTarget}
          data-link-type={this.props.dataLinkType}
          className={style}
          {...target}
          href={this.props.link}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          data-on-page-nav={this.props.onPageNav ? true : false}
          data-on-page-nav-offset={this.props.onPageNavOffset || 0}
          data-qa-id={this.props.dataQAID}
        >
          {icon}
          {this.props.text}
        </a>
      );
    } else if (this.props.isSubmit) {
      return (
        <input
          id={this.props.id}
          data-testid={this.props.id}
          type="submit"
          className={style}
          onClick={this.props.onClick}
          disabled={this.props.disabled}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          data-on-page-nav={this.props.onPageNav ? true : false}
          data-on-page-nav-offset={this.props.onPageNavOffset || 0}
          value={this.props.text as string}
          data-qa-id={this.props.dataQAID}
        />
      );
    } else if (this.props.hasChildren) {
      return (
        <button
          id={this.props.id}
          data-testid={this.props.id}
          className={style}
          onClick={this.props.onClick}
          disabled={this.props.disabled}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
        >
          {this.props.children}
        </button>
      );
    }
    return (
      <button
        id={this.props.id}
        data-testid={this.props.id}
        className={style}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        data-on-page-nav={this.props.onPageNav ? true : false}
        data-on-page-nav-offset={this.props.onPageNavOffset || 0}
        data-qa-id={this.props.dataQAID}
      >
        {icon}
        {this.props.text}
      </button>
    );
  }
}
