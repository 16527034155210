export default function loadGoogleAuthAPIs() {
  // Load required scripts for Google Auth
  if (typeof window !== "undefined" && !window.hasOwnProperty("gapi")) {
    let script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://apis.google.com/js/platform.js?onload=init"
    );
    document.body.appendChild(script);
  }
}
