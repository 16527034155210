import { useState, FormEvent } from "react";
import { User } from "SKNUI/interfaces/user";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-light-svg-icons/faCircle";
import { faHomeHeart } from "@fortawesome/pro-solid-svg-icons/faHomeHeart";
import { faUnlock } from "@fortawesome/pro-solid-svg-icons/faUnlock";

import Button from "Static/components/button";
import TextField from "SKNUI/text-field/text-field";
import Arcane from "SKNUI/arcane/arcane";
import GoogleLoginBtn from "SKNUI/google/button";
import loadGoogleAuthAPIs from "SKNUI/google/gapi-loader";

import { useEmailInput } from "SKNUI/scripts/use-input";
import Dialog from "SKNUI/yodel/yodel";

interface Props {
  isResetVariant?: boolean;
  user: User;
}

export default function SaveProgressDialog(props: Props) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail, emailError] = useEmailInput("");
  const [emailConfirm, setEmailConfirm] = useState<string | undefined>();

  function handleSubmit(e: FormEvent) {
    if (emailError && email === emailConfirm) {
      setLoading(true);
    } else {
      e.preventDefault();
    }
  }

  function handleReset() {
    setLoading(true);
    window.location.href = "/members/logout/";
  }

  const showCallback = () => {
    loadGoogleAuthAPIs();
  };

  return (
    <Dialog
      id="dialog-save-progress"
      baseModifierClass="SaveProgressDialog"
      title={
        props.isResetVariant ? (
          <>
            Warning <span>: This will delete all your progress!</span>
          </>
        ) : (
          "Save your progress"
        )
      }
      trackingName={props.isResetVariant ? "Reset Progress" : "Save Progress"}
      onDialogShow={showCallback}
      loading={loading}
    >
      <div className="Dialog-content" data-testid="dialog-save-progress">
        {props.isResetVariant ? (
          <p>
            Let us email you a link to access your account. We don’t spam or
            re-sell emails, and you can delete your account and data at any
            time.
          </p>
        ) : (
          <p>We’ll email you a link to let you log back into your account.</p>
        )}
      </div>

      <form
        className="Dialog-form"
        method="post"
        onSubmit={handleSubmit}
        action={props.user.environment.memberRegistrationUrl}
      >
        <input type="hidden" name="registration_form" value="email_only" />
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          value={props.user.csrfToken}
        />

        <Arcane
          label="Email"
          field={
            <TextField
              fieldClass="Dialog-input"
              type="email"
              name="email"
              id="save-email"
              placeholder="you@example.com"
              required={true}
              onChange={({ currentTarget }) => setEmail(currentTarget.value)}
            />
          }
        />
        <Arcane
          label="Confirm Email"
          error="Email must match"
          field={
            <TextField
              okay={
                (email && emailConfirm && email === emailConfirm) ||
                !emailConfirm
              }
              fieldClass="Dialog-input"
              type="email"
              name="email_confirm"
              id="save-email_confirm"
              required={true}
              onChange={({ currentTarget }) =>
                setEmailConfirm(currentTarget.value)
              }
            />
          }
        />

        <Button extra="Dialog-button" fill color="black" text="Save Progress" />
      </form>

      <GoogleLoginBtn
        divStyle="Dialog-google-div"
        buttonStyle="Dialog-google-button"
        displayError={true}
      />

      <footer className="Dialog-footer">
        {props.isResetVariant ? (
          <a
            className="Dialog-footer-link"
            href="#"
            onClick={() => handleReset()}
          >
            I do not want to save my data, log me out permanently.
          </a>
        ) : (
          [
            <div className="Dialog-footer-item" key="footer-item-1">
              <div className="fa-layers fa-fw Dialog-footer-icon">
                <FontAwesomeIcon icon={faCircle} />
                <FontAwesomeIcon
                  icon={faHomeHeart}
                  transform="shrink-7 left-1"
                />
              </div>
              <div className="Dialog-footer-text">
                <strong>Come back any time</strong>
                <br />
                <small>Save your answers, matches and reports.</small>
              </div>
            </div>,
            <div className="Dialog-footer-item" key="footer-item-2">
              <div className="fa-layers fa-fw Dialog-footer-icon">
                <FontAwesomeIcon icon={faCircle} />
                <FontAwesomeIcon icon={faUnlock} transform="shrink-7" />
              </div>
              <div className="Dialog-footer-text">
                <strong>Claim your data</strong>
                <br />
                <small>Instantly scrub yourself from our database.</small>
              </div>
            </div>,
          ]
        )}
      </footer>
    </Dialog>
  );
}
