import { FormEvent, MouseEvent, useEffect, useState } from "react";

import Autosuggest from "react-autosuggest";
import Fuse from "fuse.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons/faSpinnerThird";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { rendererIsClient } from "../scripts/utils";
import { trackMixpanelEvent } from "../mixpanel/mixpanel";
import { goFetch } from "../fetch/fetch";

interface SuggestionItem {
  id: number;
  slug: string;
  tokens: string[];
  urlPrefix: string;
  value: string;
  level?: number;
}

interface DatasetItem {
  title: string;
  suggestions: SuggestionItem[];
}

const fuseSettings = {
  shouldSort: true,
  findAllMatches: true,
  threshold: 0.1,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: [
    {
      name: "value",
      weight: 0.7,
    },
    {
      name: "tokens",
      weight: 0.1,
    },
  ],
};

export default function GlobalSearch() {
  let autosuggest: Autosuggest<SuggestionItem, DatasetItem> | null;

  const defaultCareers =
    rendererIsClient() && window?.localStorage?.getItem("careers") !== null
      ? JSON.parse(window?.localStorage?.getItem?.("careers") ?? "")
      : [];

  const defaultDegrees =
    rendererIsClient() && window?.localStorage?.getItem("degrees") !== null
      ? JSON.parse(window?.localStorage?.getItem?.("degrees") ?? "")
      : [];

  const [value, setValue] = useState("");
  const [careers, setCareers] = useState<SuggestionItem[]>(defaultCareers);
  const [degrees, setDegrees] = useState<SuggestionItem[]>(defaultDegrees);
  const [dataset, setDataset] = useState<DatasetItem[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [suggestions, setSuggestions] = useState<DatasetItem[]>([]);
  const [loadingResult, setLoadingResult] = useState(false);
  const [loading, setLoading] = useState(false);

  function updateDataset() {
    const d = degrees.sort((a, b) => {
      if (a.level && b.level) {
        return b.level - a.level;
      }
      return 0;
    });

    setDataset([
      {
        title: "",
        suggestions: [...careers, ...d],
      },
    ]);
  }

  /**
   * Performs the call and data massaging for auto complete endpoints.
   */
  async function handleToggleSearch(
    url: string,
    prefix: string
  ): Promise<SuggestionItem[]> {
    const response = await goFetch<{ format: "json" }, SuggestionItem[]>(
      "GET",
      url,
      {
        format: "json",
      }
    );
    if (response) {
      const c = response.map((career: SuggestionItem) => {
        career.urlPrefix = prefix;
        return career;
      });
      return c;
    }
    return [];
  }

  /**
   * Performs delegation of asychronous auto complete handler calls.
   */
  async function toggleSearch(e: MouseEvent | FocusEvent) {
    e.preventDefault();
    if (careers.length <= 0) {
      setLoading(true);
      try {
        const data = await handleToggleSearch(
          "/api/v2/careerautocomplete/",
          "/careers/"
        );
        window.localStorage.setItem("careers", JSON.stringify(data));
        setCareers(data);
        setLoading(false);
        updateDataset();
      } catch (err) {
        trackMixpanelEvent("Search Careers Fetch Error", undefined);
      }
    }

    if (degrees.length <= 0) {
      try {
        setLoading(true);
        const data = await handleToggleSearch(
          "/api/v2/degreeautocomplete/",
          "/degrees/"
        );
        window.localStorage.setItem("degrees", JSON.stringify(data));
        setDegrees(data);
        setLoading(false);
        updateDataset();
      } catch (err) {
        trackMixpanelEvent("Search Degrees Fetch Error", undefined);
      }
    }

    trackMixpanelEvent(
      `Global Search: ${!expanded ? "Open" : "Close"}`,
      window.mixpanel_track_links_json
    );

    setValue("");
    setExpanded(!expanded);
  }

  function getSuggestions(value: string): DatasetItem[] {
    const inputValue = value.trim().toLowerCase();

    const ds = dataset
      .map((section) => {
        const fuse = new Fuse(section.suggestions, fuseSettings);
        const results = fuse.search(inputValue)?.map((item) => item.item) ?? [];

        return {
          title: section.title,
          suggestions: results.slice(0, 10),
        };
      })
      .filter((section) => section.suggestions.length > 0);

    ds.push({
      title: "Or search for...",
      suggestions: [
        {
          value: inputValue,
          id: 90000,
          slug: "other",
          tokens: ["other"],
          urlPrefix: "",
        },
      ],
    });

    return inputValue.length === 0 ? [] : ds;
  }

  function renderSuggestion(suggestion: SuggestionItem) {
    let level = "Career";

    if (suggestion.level) {
      if (suggestion.level === 1) {
        level = "Area of Study";
      } else if (suggestion.level === 2) {
        level = "Degree Category";
      } else {
        level = "Degree";
      }
    }

    return (
      <div>
        {suggestion.value}
        {level ? (
          <div className="react-autosuggest__suggestion__level">{level}</div>
        ) : (
          ""
        )}
      </div>
    );
  }

  function onSuggestionSelected(
    selected: Autosuggest.SuggestionSelectedEventData<SuggestionItem>
  ) {
    setLoadingResult(true);

    trackMixpanelEvent("Global Search: Select", {
      "Search Selection": selected.suggestionValue,
      ...window.mixpanel_track_links_json,
    });

    if (selected.suggestion.urlPrefix) {
      window.location.href = `${selected.suggestion.urlPrefix}${selected.suggestion.slug}/`;
    } else {
      window.location.href = `/search/?q=${selected.suggestionValue}`;
    }
  }

  const inputProps = {
    placeholder: "Search careers and degrees",
    value: value,
    onChange: (e: FormEvent, { newValue }: Autosuggest.ChangeEvent) =>
      setValue(newValue),
  };

  useEffect(() => {
    updateDataset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (expanded) {
      window.setTimeout(() => {
        requestAnimationFrame(() => {
          if (autosuggest) {
            autosuggest.input?.focus?.();
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return expanded ? (
    <div
      className="GlobalSearch GlobalSearch--open GlobalNav-menu-link"
      data-testid="globalsearch-open"
    >
      <FontAwesomeIcon
        className="GlobalNav-icon GlobalSearch-icon GlobalSearch-icon--search"
        icon={faSearch}
      />

      <Autosuggest
        ref={(ref) => (autosuggest = ref)}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        suggestions={suggestions as any}
        highlightFirstSuggestion={true}
        onSuggestionsFetchRequested={(input) =>
          setSuggestions(getSuggestions(input.value))
        }
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionSelected={(e, selection) => onSuggestionSelected(selection)}
        getSuggestionValue={(suggestion) => suggestion.value}
        renderSuggestion={(suggestion) => renderSuggestion(suggestion)}
        inputProps={inputProps}
        multiSection={true}
        getSectionSuggestions={(section: DatasetItem) => section.suggestions}
        renderSectionTitle={(section) => <strong>{section.title}</strong>}
      />

      <span onClick={(e) => toggleSearch(e)}>
        <FontAwesomeIcon
          className="GlobalNav-icon GlobalSearch-icon GlobalSearch-icon--close"
          icon={loadingResult ? faSpinnerThird : faTimes}
          aria-label="Close Search"
          spin={loadingResult ? true : false}
          transform={loadingResult ? "up-8 shrink-3" : ""}
        />
      </span>
    </div>
  ) : (
    <a
      href="#"
      className="GlobalSearch GlobalNav-menu-link"
      aria-label="Click to open site-wide search"
      onClick={(e) => toggleSearch(e)}
      data-testid="globalsearch-closed"
    >
      <FontAwesomeIcon
        className="GlobalNav-icon"
        icon={loading ? faSpinnerThird : faSearch}
        spin={loading ? true : false}
        size="lg"
      />
    </a>
  );
}
