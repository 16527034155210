// This is the component in the career explorer repo that consumes the abstract component
import { FC, useState, MouseEvent, useEffect, useContext } from "react";

import GlobalNav, { NavMenu } from "./global-nav";
import GlobalNavItem from "./global-nav-item";
import { UserIcon } from "./user-icon";
import { User } from "../interfaces/user";

import GlobalSearch from "./global-search";
import SokanuLogo from "Static/components/sokanu-logo";
import Tooltip from "../tooltip/tooltip";
import { trackMixpanelEvent } from "../mixpanel/mixpanel";

import SignUpDialog from "Static/components/Dialogs/sign-up-dialog";
import LoginDialog from "Static/components/Dialogs/login-dialog";
import ForgotPasswordDialog from "Static/components/Dialogs/forgot-password-dialog";
import SaveProgressDialog from "Static/components/Dialogs/save-progress-dialog";
import { PaymentModal as PaymentModalSelfServe } from "../payment-modal/payment-modal-self-serve";
import { rendererIsClient } from "../scripts";
import { closeModal, openModal } from "SKNUI/yodel/utils";

import { GoogleContext, GoogleProvider } from "SKNUI/google/auth-provider";

interface Props {
  user: User;
  registrationModal: boolean;
  popOverCopy: string;
}

const CareerExplorerGlobalNav: FC<Props> = ({
  user,
  popOverCopy,
  registrationModal = true,
}: Props) => {
  /////////////////
  // State, etc. //
  /////////////////
  const { signOut } = useContext(GoogleContext);

  const [signUpLoading, setSignUpLoading] = useState(false);
  const [isSaveProgressReset, setIsSaveProgressReset] = useState(false);
  const [sticky, setSticky] = useState(false);
  /**
   * Custom event value passed when completing the `email.tsx`
   * step of the `history-and-goals` assessment
   */
  const [hallpass, setHallpass] = useState(false);

  //////////////
  // Handlers //
  //////////////

  function registerDeferredMember() {
    setSignUpLoading(true);
    window.location.href = "/members/register/deferred/";
  }

  function handleForgotPasswordClick(e: MouseEvent) {
    e.preventDefault();

    closeModal("dialog-login");
    openModal("dialog-forgot-password");
  }

  function handleSaveProgressClick(e: MouseEvent) {
    e.preventDefault();

    trackMixpanelEvent(
      "Save Progress Header Button Clicked",
      window.mixpanel_track_links_json
    );

    setIsSaveProgressReset(false);
    openModal("dialog-save-progress");
  }

  function handleResetClick() {
    setIsSaveProgressReset(true);
    openModal("dialog-save-progress");
  }

  function handleSignUpClick() {
    trackMixpanelEvent(
      "Register Header Button Clicked",
      window.mixpanel_track_links_json
    );

    if (!registrationModal) {
      registerDeferredMember();
    } else {
      openModal("dialog-sign-up");
    }
  }

  ///////////
  // Hooks //
  ///////////

  useEffect(() => {
    function handleHallpass({ detail }: CustomEvent) {
      setHallpass(detail.open);
    }

    if (user.deferred) {
      window.addEventListener("hallpass", handleHallpass);
    }

    return () => {
      if (user.deferred) {
        window.removeEventListener("hallpass", handleHallpass);
      }
    };
  });

  useEffect(() => {
    function handleScroll() {
      setSticky(window.scrollY > 10);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  ///////////////////////////////////////////
  // Global navigation content definitions //
  ///////////////////////////////////////////

  function getMainMenuItems(): NavMenu {
    const membersMenuItems: NavMenu =
      user.authenticated &&
      !user.isOrgMember &&
      !user.isOrgAdmin &&
      !user.isSchool
        ? [
            {
              name: "Partners",
              link: "/membership/partners/",
            },
          ]
        : [];

    const exploreMenuItems: NavMenu = [
      {
        name: "Careers",
        link: "/careers/",
      },
      {
        name: "Degrees",
        link: "/degrees/",
      },
      ...(!user.isSchool && !user.isOrgMember
        ? [
            {
              name: "Community",
              link: "/community/",
            },
          ]
        : []),
      {
        name: "More",
        link: "#",
        submenu: [
          {
            name: "Jobs",
            link: "/jobs/",
          },
          ...membersMenuItems,
          {
            name: "Blog",
            link: "/blog/",
          },
        ],
      },
    ];

    const loggedOutMembersMenuItems: NavMenu =
      !user.isSchool && !user.isOrgMember
        ? [
            {
              name: "For Organizations",
              link: "/for-organizations/",
            },
          ]
        : [];

    const loggedOutMenuItems: NavMenu = [
      {
        name: "Home",
        link: "/",
        mobileOnly: true,
      },
      {
        name: "Career Test",
        link: "/career-test/",
      },
      ...loggedOutMembersMenuItems,
      ...exploreMenuItems,
    ];

    const orgMemberMenuItems: NavMenu = user.isOrgAdmin
      ? [
          {
            name: "Admin Dashboard",
            link: "/organizations/",
          },
          {
            name: "Organization Settings",
            link: "/organizations/settings/",
          },
        ]
      : [];

    const orgFaqMenuItem: NavMenu =
      user.activeFlags?.includes("faqs_organization_dashboard") &&
      user.isOrgAdmin
        ? [
            {
              name: "FAQ",
              link: "/faqs/organization-dashboard/",
            },
          ]
        : [];

    const progressMenuItems: NavMenu =
      user.progress.overall === 100
        ? [
            {
              name: "My Results",
              link: "/results/",
            },
          ]
        : [];

    const schoolStaffMenuItems: NavMenu =
      user.staffFor && user.staffFor.length >= 1
        ? [
            ...(user.staffFor.length === 1
              ? [
                  {
                    name: `${user.staffFor[0].name} Dashboard`,
                    link: `/edu/${user.staffFor[0].slug}/dashboard/`,
                  },
                ]
              : [
                  {
                    name: "Schools",
                    link: "#",
                    submenu: user.staffFor.map((school) => ({
                      name: `${school.name} Dashboard`,
                      link: `/edu/${school.slug}/dashboard/`,
                    })),
                  },
                ]),
          ]
        : [];

    const loggedInMenuItems: NavMenu = [
      ...orgMemberMenuItems,
      ...orgFaqMenuItem,
      ...schoolStaffMenuItems,
      ...(!user.isOrgAdmin ? progressMenuItems : []),
      ...exploreMenuItems,
    ];
    return user.authenticated ? loggedInMenuItems : loggedOutMenuItems;
  }

  function getMainMenuExtraItems() {
    const mainMenuExtraItems =
      !user.authenticated ||
      (user.authenticated && user.progress.overall !== 100)
        ? [<GlobalSearch key="global-search" />]
        : [];
    return mainMenuExtraItems;
  }

  function GetAsideMenuItems() {
    let asideMenuItems = [];

    if (!user.authenticated && !user.isFromMembershipAd) {
      asideMenuItems.push([
        <div
          className="GlobalNav-menu-item--login"
          key={`global-nav-item--login`}
        >
          <GlobalNavItem
            key={`global-nav-item--login`}
            name="Log In"
            data-qa-id="login-button"
            handler={(e) => {
              e?.preventDefault();
              trackMixpanelEvent(
                "Login Header Button Clicked",
                window.mixpanel_track_links_json
              );

              openModal("dialog-login");
            }}
          />
        </div>,
        <button
          className="GlobalNav-button GlobalNav-button--main alans-butt--grey"
          onClick={() => handleSignUpClick()}
          key={`global-nav-item--test-button`}
        >
          Take the <span className="hide-below-md">&nbsp;free&nbsp;</span> test
        </button>,
      ]);
    } else if (user.deferred && !hallpass) {
      asideMenuItems.push(
        <div
          className="GlobalNav-save-progress GlobalNav-button--main"
          key={`global-nav-item--progress-button`}
        >
          <button
            onClick={(e) => handleSaveProgressClick(e)}
            className="alans-butt--grey"
            data-testid="member-deferred-save-progress-trigger"
          >
            Save your progress
          </button>
          <div onClick={(e) => handleSaveProgressClick(e)}>
            <Tooltip
              expanded={false}
              key="saveProgressTooltip"
              text={popOverCopy}
              popover={{ delay: 5000, duration: 4000 }}
              animation="slide"
            />
          </div>
        </div>
      );
    } else if (user.progress.overall !== 100) {
      asideMenuItems.push(
        <button
          className="GlobalNav-button--main alans-butt--grey"
          onClick={(): string => (window.location.href = "/assessments/")}
          key={`global-nav-item--assessment-button`}
        >
          Take the test
        </button>
      );
    }

    if (
      user.authenticated &&
      !user.deferred &&
      !(user.authenticated && user.progress.overall !== 100)
    ) {
      asideMenuItems.push(
        <div className="GlobalNav-button--main" key={`global-nav-search`}>
          <GlobalSearch />
        </div>
      );
    }
    if (user.authenticated) {
      asideMenuItems.push(
        <div
          className="GlobalNav-menu-item GlobalNav-menu-item--account"
          key={`global-nav-item--settings`}
        >
          <GlobalNavItem
            name={"Account"}
            anchor={<UserIcon name={user.fullName} />}
            variant="user"
            ariaLabel="Account Settings"
            submenu={
              user.deferred
                ? [
                    {
                      name: "Reset Assessment",
                      link: "/settings/security-and-privacy/#reset-account",
                    },
                    {
                      name: "Log out",
                      handler: () => {
                        handleResetClick();
                      },
                    },
                  ]
                : [
                    {
                      name: "Reset Assessment",
                      link: "/settings/security-and-privacy/#reset-account",
                    },
                    {
                      name: "Settings",
                      link: "/settings/basic-details/",
                    },
                    ...(user.staff
                      ? [
                          {
                            name: "Admin",
                            link: "/admin/",
                          },
                          {
                            name: "Cache Buster 💥",
                            link: "/cache-buster/",
                          },
                          {
                            name: "Media Upload",
                            link: "/media-upload/",
                          },
                        ]
                      : []),
                    {
                      name: "Log out",
                      handler: () =>
                        signOut(
                          () => (window.location.href = "/members/logout/"),
                          () => console.log("Failed to Logout Google")
                        ),
                    },
                  ]
            }
          />
        </div>
      );
    }
    return asideMenuItems;
  }

  ////////////////////////
  // Dialog definitions //
  ////////////////////////

  function getDialogs() {
    const common = { user };
    return (
      <>
        {rendererIsClient() && !user.authenticated && !user.deferred
          ? [
              registrationModal && (
                <SignUpDialog
                  {...common}
                  key="sign-up-dialog"
                  loading={signUpLoading}
                />
              ),
              <LoginDialog
                {...common}
                key="login-dialog"
                handleForgotPasswordClick={handleForgotPasswordClick}
              />,
              <ForgotPasswordDialog key="forgot-password-dialog" />,
            ]
          : rendererIsClient() &&
            user.deferred && (
              <SaveProgressDialog
                {...common}
                isResetVariant={isSaveProgressReset}
              />
            )}

        {rendererIsClient() && <PaymentModalSelfServe {...common} />}
      </>
    );
  }

  /////////////////////////
  // Happy little render //
  /////////////////////////

  return (
    <>
      <GlobalNav
        mainMenu={getMainMenuItems()}
        mainMenuExtra={getMainMenuExtraItems()}
        asideMenu={GetAsideMenuItems()}
        logo={<SokanuLogo className="GlobalNav-logo" />}
        classNames={sticky ? " GlobalNav--sticky" : ""}
      />
      {getDialogs()}
    </>
  );
};

// eslint-disable-next-line react/display-name
export default (props: Props) => {
  return (
    <GoogleProvider
      clientId={props.user?.environment?.googleSignInClientId}
      loginUrl={props.user?.environment?.googleLoginUrl}
    >
      <CareerExplorerGlobalNav {...props} />
    </GoogleProvider>
  );
};
